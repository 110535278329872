import axios from "axios";
const headers = {};

let baseURL;
if (process.env.REACT_APP_PRODUCTION == "true") {
  baseURL = process.env.REACT_APP_BASE_URL;
} else {
  baseURL = "/";
}
console.log(baseURL);
const instance = axios.create({
  baseURL,
  timeout: 10000,
  headers,
});

export default instance;
