import mock from "../mock";
import { paginateArray, sortCompare } from "../utils";

import { employeesData } from "./employees_data";

// ------------------------------------------------
// GET: Return employees
// ------------------------------------------------
mock.onGet(/\/api\/employees?.*/).reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 6,
    page = 1,
    sort = "id,desc",
    departmentName = null,
  } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();

  /* eslint-disable */
  let filteredData;
  try {
    filteredData = employeesData.filter(
      (employee) =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        (employee.hireDate.toLowerCase().includes(queryLowered) ||
          employee.departmentName.toLowerCase().includes(queryLowered) ||
          employee.email.toLowerCase().includes(queryLowered) ||
          employee.firstName.toLowerCase().includes(queryLowered) ||
          employee.lastName.toLowerCase().includes(queryLowered)) &&
        employee.departmentName === (departmentName || employee.departmentName)
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  const sortValue = sort.split(",")[0];
  const sortDesc = sort.split(",")[1] === "desc";
  const sortedData = filteredData.sort(sortCompare(sortValue));
  if (sortDesc) sortedData.reverse();

  const filteredGratuity = filteredData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.curGratuity,
    0
  );
  const totalGratuity = employeesData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.curGratuity,
    0
  );
  const percentGratuity = ((filteredGratuity / totalGratuity) * 100).toFixed(2);

  /* eslint-disable object-shorthand */
  return [
    200,
    {
      content: paginateArray(sortedData, perPage, page + 1),
      totalElements: filteredData.length,
      filtGratuity: filteredGratuity,
      totGratuity: totalGratuity,
      percGratuity: percentGratuity,
    },
  ];
  /* eslint-enable  */
});

// ------------------------------------------------
// POST: Add new employee
// ------------------------------------------------
mock.onPost("/api/employees").reply((config) => {
  // Get event from post data
  const employee = JSON.parse(config.data);

  // Assign Status
  employee.status = "active";

  const { length } = employeesData;
  let lastIndex = 0;
  if (length) {
    lastIndex = employeesData[length - 1].id;
  }
  employee.id = lastIndex + 1;

  employeesData.push(employee);

  return [201, { employee }];
});

// ------------------------------------------------
// PUT: Edit employee
// ------------------------------------------------
mock.onPut("/api/employees").reply((config) => {
  // Get event from post data
  const employee = JSON.parse(config.data);

  employeesData = employeesData.map((item) => {
    if (item.id === employee.id) {
      return employee;
    }
    return item;
  });

  return [201, { employee }];
});

// ------------------------------------------------
// GET: Return Single employee
// ------------------------------------------------
mock.onGet(/\/api\/employees\/\d+/).reply((config) => {
  // Get event id from URL
  let employeeId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  employeeId = Number(employeeId);

  const employeeIndex = employeesData.findIndex((e) => e.id === employeeId);
  const employee = employeesData[employeeIndex];

  if (employee) return [200, employee];
  return [404];
});

// ------------------------------------------------
// POST: Add new employee
// ------------------------------------------------
mock.onPost("/api/employees/import").reply(() => {
  // Get event from post data
  employeesData.push({
    id: 31,
    uniqueID: "25446838298400",
    firstName: "Testing",
    lastName: "Test",
    middleName: "TestinTest",
    email: "testing.test@Awesome-company.com",
    hireDate: "2016-08-10T20:00:00Z",
    offboardingDate: null,
    basicSalary: 12020,
    unlimitedContract: true,
    departmentName: "Finance",
    companyId: 11,
    curGratuity: 36975,
    eligibilityTier: "<5Y",
    monthlyGratuity: 701.1666666667,
    ratioOverallGratuity: 0.0081959219,
    seniority: 4.3945205479,
  });

  return [201];
});

// ------------------------------------------------
// POST: Compute gratuity
// ------------------------------------------------
mock.onPost(/\/api\/employees\/\d+\/compute-gratuity/).reply(() => {
  const returnedData = {
    unlimitedContract: "no",
    resignation: "no",
    offboardingDate: "",
    gratuity: 12345,
  };

  return [200, returnedData];
});

// ------------------------------------------------
// POST: Offboard
// ------------------------------------------------
mock.onPost(/\/api\/employees\/.*\/offboard/).reply(() => [200]);
