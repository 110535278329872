import mock from "../mock";
import { paginateArray } from "../utils";

import { data } from "./investment-data";

// ------------------------------------------------
// GET: Return the Overall Dashboard Summary
// ------------------------------------------------
mock
  .onGet("/api/investment-accounts/summary")
  .reply(() => [200, data.allDashboard]);

// ------------------------------------------------
// GET: Return the Savings Account Summary
// ------------------------------------------------
mock
  .onGet("/api/investment-accounts/summary/savings")
  .reply(() => [200, data.savingsAccount]);

// ------------------------------------------------
// GET: Return the Investments Account Summary
// ------------------------------------------------
mock
  .onGet("/api/investment-accounts/summary/investments")
  .reply(() => [200, data.investmentAccount]);

// ------------------------------------------------
// Get: Default Questionnaire
// ------------------------------------------------
mock
  .onGet("/api/risk-assessments/questionnaire")
  .reply(() => [200, data.defaultQuestionnaire]);

// ------------------------------------------------
// Post: The Risk Assessment
// ------------------------------------------------
mock
  .onPost("/api/risk-assessments/assess")
  .reply(() => [200, data.assessedRiskLevel3]);

// ------------------------------------------------
// Post: Risk Helpers
// ------------------------------------------------
mock.onPost("/api/investment-helpers").reply(() => [200, data.helperForecast2]);

// ------------------------------------------------
// GET: Return the list of portfolios matching a risk level
// ------------------------------------------------
mock.onGet(/\/api\/portfolio-definitions\/summary?.*/).reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const { risklevel = null, portfoliotype = null } = config.params;

  let result;
  let filteredData;
  // If both the risk level and the portfoliotype have been provided, only one portfolio will match
  if (portfoliotype && risklevel) {
    try {
      filteredData = data.portfolioSummaryList.content.filter(
        (p) => p.risk_level.id === risklevel
      );
    } catch (e) {
      /* eslint-disable no-console */
      console.error(`${e.name} : ${e.message}`);
      /* eslint-enable no-console */
    }

    result = {
      content: paginateArray(filteredData, 10, 1),
      totalElements: filteredData.length,
    };
  } else {
    const riskLevelEntity = data.riskLevels.filter(
      (r) => r.id === risklevel
    )[0];
    try {
      filteredData = data.portfolioSummaryList.content.filter(
        (p) => p.risk_level.rank <= riskLevelEntity.rank
      );
    } catch (e) {
      /* eslint-disable no-console */
      console.error(`${e.name} : ${e.message}`);
      /* eslint-enable no-console */
    }

    result = {
      content: paginateArray(filteredData, 10, 1),
      totalElements: filteredData.length,
    };
  }
  return [200, result];
});

// ------------------------------------------------
// Get: The list of portfolio types
// ------------------------------------------------
mock.onGet("/api/portfolio-types").reply(() => [200, data.portfolioTypesList]);

// ------------------------------------------------
// Post: Create a new investment
// ------------------------------------------------
mock
  .onPost("/api/investment-accounts")
  .reply(() => [200, data.newInvestmentAccountResponse]);

// ------------------------------------------------
// Post: Contribute / Withdrawal
// ------------------------------------------------
mock
  .onPost("/api/investment-orders")
  .reply(() => [200, data.newInvestmentAccountResponse]);

// ------------------------------------------------
// List Investment Orders
// ------------------------------------------------
mock
  .onGet(/\/api\/investment-orders?.*/)
  .reply(() => [200, data.QATestInvestmentOrdersList]);

// ------------------------------------------------
// Post: Create document to sign by the client
// We have to embed a document in our webapp
// see example: https://developers.pandadoc.com/reference/share-document#embed-a-document
// In example you will see https://app.pandadoc.com/s/{id} in iframe, where {id} = data.newDocument.session_id
// ------------------------------------------------
mock.onPost("/api/agreements").reply(() => [200, data.newDocument]);
